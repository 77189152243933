import { AxiosRequestConfig } from 'axios'
import axiosInstance from '.'

interface Response {
  tenants: Tenant[]
}

export interface Tenant {
  id: string
  displayName: string
}

export async function getTenantsByEmailAddress(email: string, product?: string): Promise<Response | undefined> {
  const options: AxiosRequestConfig = {
    params: { email, product },
  }
  const { data } = await axiosInstance.get('/tenants', options)

  return data as Response
}

export async function getTenantsByExternalEntity(
  externalEntityId: string,
  dataCenter?: string,
  product?: string,
): Promise<Response | undefined> {
  const options: AxiosRequestConfig = {
    params: { externalEntityId, dataCenter, product },
  }
  const { data } = await axiosInstance.get('/tenants', options)

  return data as Response
}
