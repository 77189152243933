import { FC } from 'react'
import { Stack, Button, Typography, Link, Alert } from '@papercutsoftware/pcds-react'
import { useRouter } from 'next/router'
import { Tenant } from '@/api/tenants'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'

interface Props {
  tenants: Tenant[]
  userEmailAddress: string
  onChangeEmailAddressClicked: () => void
}

const TenantSelector: FC<Props> = ({ tenants, userEmailAddress, onChangeEmailAddressClicked }) => {
  const sortedTenants = [...tenants]?.sort((a: Tenant, b: Tenant) => a.displayName?.localeCompare(b.displayName))
  const router = useRouter()

  return (
    <StyledFormWrapper direction="column" spacing={3}>
      <Stack direction="column" spacing={2}>
        <Typography color="heading" variant="h2" component="h2">
          Select your organization ID
        </Typography>
        <Typography color="default" variant="label" component="p">
          Organizations for <strong>{userEmailAddress}</strong>
        </Typography>
      </Stack>
      <Stack direction="column" spacing={2}>
        {sortedTenants?.map(({ id, displayName }) => (
          <Button
            key={id}
            label={displayName}
            variation="secondary"
            onClick={() => {
              router.push({
                query: { ...router.query, tenantId: id, prefilledEmail: userEmailAddress },
              })
            }}
          />
        ))}
      </Stack>
      <Stack>
        <Typography>Can&apos;t find your organization?</Typography>
        <Link href="#" onClick={onChangeEmailAddressClicked} target={'_self'}>
          Try a different email address
        </Link>
      </Stack>
      <Alert severity={'info'} title={'To more easily switch organizations, bookmark this page.'} />
    </StyledFormWrapper>
  )
}
export default TenantSelector
